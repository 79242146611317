import axios from 'axios'
import appConfig from '../config'

const donApi = function (timeout = appConfig.don.apiTimeout) {
  const _client = axios.create({
    baseURL: appConfig.don.apiUrl(),
    timeout: timeout * 1000,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return _client
}

export default donApi
