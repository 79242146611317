<template>
  <section>
    <div class="card">
      <div class="card-body">
        <app-preloader v-if="!dataLoaded"></app-preloader>
        <div v-if="dataLoaded">
          <div class="row">
            <div class="col-lg-2">
              <app-select
                v-model="filter.site"
                :options="sites"
                :label="$t('hp.filter.site')"
                id="filter_site"
                no-empty-value
                @change="setFilterSite"
              >
              </app-select>
            </div>
            <div class="col-lg-10">
              <label>{{ $t('hp.filter.visible_blocks') }}</label><br>
              <template v-for="contentBlock in contentBlocks">
                <label
                  :key="contentBlock.id"
                  v-if="contentBlock.siteId == filter.site && contentBlock.hidden === false"
                  class="btn btn-secondary m-r-5 m-b-5"
                  :class="{ 'active': isContentBlockActive(contentBlock.id) }"
                >
                  <input
                    type="checkbox"
                    v-model="filter.contentBlocks"
                    :value="contentBlock.id"
                    @change="setFilter"
                    :id="'filter_content_block_' + contentBlock.id"
                  >
                  {{ contentBlock.title }}
                </label>
              </template>
              <label class="btn btn-success m-r-5 m-b-5">
                <input type="checkbox" @click="resetFilter" data-test="hp_reset_filter">
                {{ $t('hp.filter.reset') }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { CONTENT_BLOCK_TYPE_ARTICLE } from '@/components/mixins/valueObject/ContentBlockTypeMixin'
import Preloader from '../preloader/Preloader'
import Select from '../form/select/Select'
import { MODULE_CONTENT_BLOCK_ITEM } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'ContentBlockItemFilterOld',
  data () {
    return {
      dataLoaded: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    filter () {
      return this.$store.getters['contentBlock/hpManagementFilter']
    },
    contentBlocks () {
      const contentBlocks = this.$store.getters['contentBlock/all']
      return contentBlocks.filter(contentBlock => contentBlock.type === CONTENT_BLOCK_TYPE_ARTICLE)
    },
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_CONTENT_BLOCK_ITEM)
    }
  },
  components: {
    appPreloader: Preloader,
    appSelect: Select
  },
  methods: {
    init () {
      const contentBlocks = this.$store.getters['contentBlock/all']
      if (contentBlocks.length === 0) {
        this.getContentBlocks()
      } else {
        this.dataLoaded = true
      }
    },
    getContentBlocks () {
      this.dataLoaded = false
      this.$store.dispatch('contentBlock/fetchAll')
        .then(() => {
          this.dataLoaded = true
        })
        .catch(error => {
          this.dataLoaded = true
          console.log(error)
        })
    },
    isContentBlockActive (id) {
      if (this.filter.contentBlocks && this.filter.contentBlocks.indexOf(id) > -1) {
        return true
      }
    },
    setFilterSite () {
      this.filter.contentBlocks = []
      this.setFilter()
    },
    setFilter () {
      this.$store.commit('contentBlock/setLimit', 50)
      this.$store.commit('contentBlock/setPage', 1)
      this.$store.commit('contentBlock/setHpManagementFilter', this.filter)
      this.$store.dispatch('contentBlock/fetchForHpManagement')
    },
    resetFilter () {
      this.filter.contentBlocks = []
      this.$store.commit('contentBlock/setHpManagementFilter', this.filter)
      this.$store.dispatch('contentBlock/fetchForHpManagement')
    }
  },
  created () {
    this.init()
  }
}
</script>
