import { render, staticRenderFns } from "./ContentBlockItemListOld.vue?vue&type=template&id=28453044&"
import script from "./ContentBlockItemListOld.vue?vue&type=script&lang=js&"
export * from "./ContentBlockItemListOld.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports